import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slices/authentication/authSlice';
import staticPagesReducer from '../slices/static-pages/staticPagesSlice';
import roadMapReducer from '../slices/roadMap/roadMapSlice';
import carouselReducer from '../slices/carousel/carouselSlice';
import confirmRegisterContentReducer from '../slices/confirmRegisterContent/confirmRegisterContentSlice';
import editProjectContentReducer from '../slices/editProjectContent/editProjectContentSlice';
import propositionsReducer from '../slices/propositions/propositionsSlice';
import requestsReducer from '../slices/requests/requestsSlice';
import profileReducer from '../slices/profile/profileSlice';
import filesReducer from '../slices/filesToDownload/filesToDownloadSlice';
import requestDropDownsSlice from '../slices/requestDropDowns/requestDropDownsSlice';
import addressReducer from '../slices/address/addressSlice';
import usersReducer from '../slices/users/usersSlice';
import filterReducer from '../slices/filter/filterSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    staticPages: staticPagesReducer,
    roadMap: roadMapReducer,
    carousel: carouselReducer,
    confirmRegisterContent: confirmRegisterContentReducer,
    editProjectContent: editProjectContentReducer,
    propositions: propositionsReducer,
    requests: requestsReducer,
    requestDropDowns:requestDropDownsSlice,
    profile: profileReducer,
    files: filesReducer,
    address: addressReducer,
    users: usersReducer,
    filter: filterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;